
import {defineComponent, reactive, toRefs} from "vue";
import Product from "@/models/product";
import FuncUtils from "@/utils/funcUtils";

export default defineComponent({
  name: "viewProduct",
  props: {
    product: {
      required: true,
      type: Product
    }
  },

  setup() {
    const state = reactive({
      readDate: FuncUtils.getReadableDate
    })
    return {
      ...toRefs(state)
    }
  }
})
