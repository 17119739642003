
import {computed, defineComponent, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {key} from "@/store/index";
import viewProduct from "@/components/dashboard/modals/viewProduct.vue";

export default defineComponent({
  name: "allProducts",

  setup() {
    const store = useStore(key);
    store.dispatch('product/fetchByUser')
    const state = reactive({
      currentItem: {},
    })
const products = computed(() => store.getters['product/userProducts'])

    return {
      products,
      ...toRefs(state)
    }
  },
  components: {
    viewProduct
  }
})
